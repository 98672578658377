import {
    Box, Container, FormControl, Divider,
    FormLabel, Heading, Input, InputGroup, InputRightElement, SimpleGrid, Skeleton, Slider, SliderFilledTrack, Flex, useColorMode,
    SliderThumb, SliderTrack, Stack, Text, VStack, StackDivider, Link, HStack, FormErrorMessage, FormHelperText, useColorModeValue
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdGraphicEq } from 'react-icons/md';
import { AiOutlineCalendar } from 'react-icons/ai'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { getSuggestedSettings } from '../api';

const minStoragePrice = 1;
const maxStoragePrice = 5;

function calculateValues(diskSizeTB, diskUsedTB, storagePricePerTB, contractMonths, currentMonth) {
    const parsedDiskSizeTB = diskSizeTB || 0;
    const parsedDiskUsedTB = diskUsedTB || 0;
    const parsedStoragePricePerTB = storagePricePerTB || 0;
    const parsedContractMonths = contractMonths || 0;
    const freeDiskTB = parsedDiskSizeTB - parsedDiskUsedTB;

    //#region Incentives

    const d = new Date();
    const year = d.getFullYear();
    //const month = d.getMonth();
    let usedCapacityMultiplier = 75 //year > 2022 || currentMonth > 11 ? 0 : (125 - (currentMonth * 10));

    const usedCapacityIncentive = parsedDiskUsedTB * usedCapacityMultiplier;
    const incentivesEarnings = usedCapacityIncentive;
    const incentivesValid = parsedDiskSizeTB > 0 && parsedDiskUsedTB > 0 && parsedStoragePricePerTB >= minStoragePrice && parsedStoragePricePerTB <= maxStoragePrice;

    //#endregion

    //#region Collateral

    const collateralValid = parsedStoragePricePerTB > 0;
    const collateralValue = parsedDiskUsedTB * parsedStoragePricePerTB * parsedContractMonths * 2;

    //#endregion

    //#region Rent

    const rentValid = parsedStoragePricePerTB > 0;
    const rentValue = diskUsedTB * parsedStoragePricePerTB;

    //#endregion

    const rent = { valid: rentValid, value: rentValue };
    const incentives = { valid: incentivesValid, value: incentivesEarnings };
    const collateral = { valid: collateralValid, value: collateralValue };

    return { rent, incentives, collateral, total: (rent.valid ? rent.value : 0) + (incentives.valid ? incentives.value : 0), usedCapacityMultiplier, monthsLeft : 11 - currentMonth };
}

function MonthsSlider({ month, handleChangeMonth })
{
    const months =  ["January","February","March","April","May","June","July",
            "August","September","October","November","December", ''];

    return <Box px={{ base : 15, md : 20}} pt={2}>
        <Text fontSize="3xl" textAlign="center">
            Incentives
        </Text>
        <Text fontSize="xl" textAlign="center" height={6}>
            {months[month]} {month > 11 ? '  ' : 2022}
        </Text>
         <Slider aria-label="slider-ex-4" min={0} max={12} step={1} mt={2} value={month} focusThumbOnChange={false} onChange={(val) => handleChangeMonth(val)}>
            <SliderTrack >
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6} >
                <Box color='gray.800' as={AiOutlineCalendar} />
            </SliderThumb>
        </Slider>
    </Box>
}

function MonthsSlider1({ month, handleChangeMonth })
{
    const months =  ["January","February","March","April","May","June","July",
            "August","September","October","November","December", ''];

    return <Box  pt={2}>
        <Text fontSize="3xl" textAlign="left">
            Incentives
        </Text>
        <Text fontSize="xl" textAlign="center" height={5}>
            {months[month]} {month > 11 ? '  ' : 2022}
        </Text>
         <Slider aria-label="slider-ex-4" min={0} max={12} step={1} mt={2} value={month} focusThumbOnChange={false} onChange={(val) => handleChangeMonth(val)}>
            <SliderTrack >
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6} >
                <Box color='gray.800' as={AiOutlineCalendar} />
            </SliderThumb>
        </Slider>
    </Box>
}

export default function Calculator() {

    const [formData, setFormData] = useState({ size: 16, used: 1, storagePricePerTB: 5, contractMonths: 3, month : new Date().getMonth() });
    const [loaded, setLoaded] = useState(true);

    const calculatedValues = calculateValues(formData.size, formData.used, formData.storagePricePerTB, formData.contractMonths, formData.month);

    const { colorMode } = useColorMode();
    const isDark = colorMode == 'dark';

    const handleChangeSize = value => {
        const floatValue = parseFloat(value);

        let newState = { size: value };
        if (formData.used > floatValue) newState.used = value;

        setFormData(state => ({ ...state, ...newState }));
    }

    const handleChangeUsed = value => {
        const floatValue = parseFloat(value);

        let newState = { used: value };
        if (formData.size < floatValue) newState.used = formData.size;

        setFormData(state => ({ ...state, ...newState }));
    }

    /*useEffect(() => {
        const getValue = () => {
            getSuggestedSettings().then(res => {
                if (res) {
                    setLoaded(true);
                    setMinStoragePrice(res);
                }
            })
        }
        getValue();
        const interval = setInterval(() => getValue(), 600000);
        return () => {
            clearInterval(interval);
        }

    }, []);*/

    const parsedDiskSizeTB = '' + parseFloat(formData.size || 0);
    const parsedDiskUsedTB = '' + parseFloat(formData.used || 0);
    const parsedStoragePricePerTB = '' + parseFloat(formData.storagePricePerTB || 0);
    const parsedContractMonths = '' + parseFloat(formData.contractMonths || 0);
    const parsedStoragePricePerTBError = parsedStoragePricePerTB < minStoragePrice || parsedStoragePricePerTB > maxStoragePrice;

    const errorColor = (isDark ? "red.500" : "crimson");
    const borderColor = (isDark ? "blue.400" : "blue.500");

    const getColorForm = inValid => inValid ? errorColor : (isDark ? "blue.400" : "blue.500");

    const size = "md";
    const fontSize = "1.3rem";
    const backgroundColorIncentivesWarning = useColorModeValue('yellow.300', 'yellow.200');
    const hideMonthsIncentive = true ; //new Date().getFullYear() > 2022;

    return (
        <Container maxWidth="container.xl" px={{ base: 4, md: 8 }} borderRadius="md" textAlign="center" >
            <VStack divider={<StackDivider />} spacing={4}>
                <SimpleGrid width="100%" columns={{ base: 1, md: 2 }} spacing={{ base: 5, xl: 8 }} justifyContent="center" alignItems="flex-start">
                    <Flex justifyContent="center">
                        <Box maxWidth={{ md: '300px' }}>
                            <Stack spacing="3">
                                <Text fontSize="3xl" textAlign="left">
                                    Earnings
                                </Text>
                                <FormControl id="drive-space">
                                    <FormLabel size={size} textColor={formData.size == 0 ? errorColor : 'inherit'}>
                                        Total storage
                                    </FormLabel>
                                    <InputGroup size={size} >
                                        <Input
                                            type="number"
                                            fontSize={fontSize}
                                            step={.01}
                                            textAlign="center"
                                            value={parsedDiskSizeTB}
                                            onChange={ev => handleChangeSize(parseFloat(ev.currentTarget.value || 0))}
                                            isInvalid={formData.size == 0}
                                            errorBorderColor={errorColor}
                                            focusBorderColor={formData.size == 0 ? errorColor : borderColor}
                                        />
                                        <InputRightElement
                                            pointerEvents="none"
                                            children={<Text fontSize="xl" fontWeight="bold" >TB</Text>}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="used-space" >
                                    <FormLabel size={size} textColor={formData.used == 0 ? errorColor : 'inherit'}>
                                        Used storage
                                    </FormLabel>
                                    <InputGroup size={size} mb="4" >
                                        <Input
                                            type="number"
                                            fontSize={fontSize}
                                            step={.01}
                                            textAlign="center"
                                            min={0}
                                            max={formData.size}
                                            value={parsedDiskUsedTB}
                                            onChange={ev => handleChangeUsed(parseFloat(ev.currentTarget.value || 0))}
                                            isInvalid={formData.used == 0}
                                            errorBorderColor={errorColor}
                                            focusBorderColor={formData.used == 0 ? errorColor : borderColor}
                                        />
                                        <InputRightElement
                                            pointerEvents="none"
                                            children={<Text fontSize="xl" fontWeight="bold" >TB</Text>}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <Slider aria-label="slider-ex-4" min={0} max={formData.size} step={0.1} value={formData.used} focusThumbOnChange={false} onChange={(val) => handleChangeUsed(val)}>
                                    <SliderTrack >
                                        <SliderFilledTrack />
                                    </SliderTrack>
                                    <SliderThumb boxSize={6} >
                                        <Box color='gray.800' as={MdGraphicEq} />
                                    </SliderThumb>
                                </Slider>
                                <FormControl id="storage-price" isInvalid={parsedStoragePricePerTBError} >
                                    <FormLabel size={size} sx={{ mt: 2 }} textColor={parsedStoragePricePerTBError ? errorColor : 'inherit'}>
                                        Used storage price per TB/Month
                                    </FormLabel>
                                    <InputGroup size={size} >
                                        <Input
                                            type="number"
                                            fontSize={fontSize}
                                            min={minStoragePrice}
                                            max={maxStoragePrice}
                                            step={.01}
                                            textAlign="center"
                                            value={parsedStoragePricePerTB}
                                            onChange={ev => setFormData({ ...formData, storagePricePerTB: parseFloat(ev.currentTarget.value || 0) })}
                                            isInvalid={parsedStoragePricePerTBError}
                                            errorBorderColor={errorColor}
                                            focusBorderColor={parsedStoragePricePerTBError ? errorColor : borderColor}
                                        />
                                        <InputRightElement
                                            pointerEvents="none"
                                            mr={2}
                                            children={<Text fontSize="xl" fontWeight="bold" >SCP</Text>}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>To receive incentives min {minStoragePrice} and max {maxStoragePrice}</FormErrorMessage>
                                </FormControl>
                                <Box mt={2}>
                                    <Link fontSize="xs" href='https://grafana.scpri.me/' isExternal color='blue.300'>
                                        Network is currently young and usage still increasing Please refer to realistic usage amounts here <ExternalLinkIcon mx='2px' />
                                    </Link>
                                </Box>
                            </Stack>
                        </Box>
                    </Flex>
                    <Box>
                        {!hideMonthsIncentive &&
                        <MonthsSlider month={formData.month} handleChangeMonth={month => setFormData(state => ({...state, month}))} />}
                        <Heading size="xl" fontWeight="400" mt={2}>
                            Estimated earnings
                        </Heading>
                        <Heading size="xs" fontWeight="400" mt="2" >
                            * including incentives based on full uptime device availability
                        </Heading>
                        <HStack spacing="2" mt={4} width={"100%"} justify={"space-evenly"}>
                            <Skeleton isLoaded={loaded}>
                                <Heading size="2xl" >
                                    {calculatedValues.rent.valid ? calculatedValues.rent.value.toLocaleString() : '-'}
                                </Heading>
                                <Heading size="md" fontWeight="400" mt={2}>
                                    Rent
                                </Heading>
                            </Skeleton>
                            <Skeleton isLoaded={loaded}>
                                <Heading size="2xl" >
                                    {calculatedValues.incentives.valid ? calculatedValues.incentives.value.toLocaleString() : '-'}
                                </Heading>
                                <Heading size="md" fontWeight="400" mt={2}>
                                    Incentives
                                </Heading>
                            </Skeleton>
                        </HStack>
                        <Skeleton isLoaded={loaded}>
                            <Heading size="4xl" mt={4}>
                                {calculatedValues.total.toLocaleString()}
                            </Heading>
                        </Skeleton>
                        <Heading size="xl" fontWeight="400" mt={2}>
                            SCP / Month
                        </Heading>
                        <Box mt={2}>
                            <Link fontSize="sm" href='https://docs.scpri.me/storageproviderindex/storage-provider-earnings' isExternal color='blue.300'>
                                What can I expect? <ExternalLinkIcon mx='2px' />
                            </Link>
                        </Box>
                        <Text fontSize="xs" fontWeight="600" textAlign={"left"} mt="1" p={1} px={2} texts borderRadius="lg" backgroundColor={backgroundColorIncentivesWarning} color={'gray.800'}>
                              Please note that the calculations above are only estimates based on the values you entered and is not a definite prediction of the future. therefore the actual results may vary<br/>
                              {!hideMonthsIncentive && calculatedValues.usedCapacityMultiplier > 0 && `
                              Used Space pays ${calculatedValues.usedCapacityMultiplier}SCP/TB/Mo${(calculatedValues.monthsLeft > 0 ? ` (this is scheduled to decline 10SCP every month for the next ${calculatedValues.monthsLeft})` : ' (this is scheduled to end this month)')}`}
                        </Text>
                    </Box>
                </SimpleGrid>
                <SimpleGrid width="100%" columns={{ base: 1, md: 2 }} spacing={{ base: 5, xl: 8 }} mt={-2} justifyContent="center" alignItems="center">
                    <Flex justifyContent="center">
                        <Box maxWidth={{ md: '300px' }}>
                            <Stack spacing="3">
                                <Text fontSize="3xl" textAlign="left">
                                    Collateral
                                </Text>
                                <FormControl id="collateral-price">
                                    <FormLabel size={size} >
                                        Collateral price per TB/Month
                                    </FormLabel>
                                    <InputGroup size={size} >
                                        <Input
                                            type="number"
                                            fontSize={fontSize}
                                            min={minStoragePrice}
                                            step={.01}
                                            textAlign="center"
                                            disabled
                                            value={parsedStoragePricePerTB}
                                        />
                                        <InputRightElement
                                            pointerEvents="none"
                                            mr={2}
                                            children={<Text fontSize="xl" fontWeight="bold" >SCP</Text>}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl id="contract-months" >
                                    <FormLabel size={size} textColor={formData.contractMonths == 0 ? errorColor : 'inherit'}>
                                        Max contract period in months
                                    </FormLabel>
                                    <InputGroup size={size} mb="4" >
                                        <Input
                                            type="number"
                                            fontSize={fontSize}
                                            step={.01}
                                            textAlign="center"
                                            min={0}
                                            max={formData.size}
                                            value={parsedContractMonths}
                                            onChange={ev => setFormData({ ...formData, contractMonths: parseFloat(ev.currentTarget.value || 0) })}
                                            isInvalid={formData.contractMonths == 0}
                                            errorBorderColor={errorColor}
                                            focusBorderColor={formData.contractMonths == 0 ? errorColor : borderColor}
                                        />
                                        <InputRightElement
                                            pointerEvents="none"
                                            children={<Text fontSize="xl" fontWeight="bold" >M</Text>}
                                        />
                                    </InputGroup>
                                </FormControl>
                            </Stack>
                        </Box>
                    </Flex>
                    <Box>
                        <Heading size="2xl" fontWeight="400" mt={2}>
                            Collateral needed
                        </Heading>
                        <Skeleton isLoaded={loaded}>
                            <Heading size="4xl" mt={4}>
                                {calculatedValues.collateral.valid ? calculatedValues.collateral.value.toLocaleString() : '-'}
                            </Heading>
                        </Skeleton>
                        <Heading size="xl" fontWeight="400" mt={2}>
                            SCP
                        </Heading>
                    </Box>
                </SimpleGrid>
            </VStack>
        </Container>
    );
}
