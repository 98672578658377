import {
    Box, Flex, Skeleton, Spacer, Stack, Text, useBreakpointValue, useColorModeValue, Fade
} from '@chakra-ui/react';
import { round } from 'mathjs';
import React, { useEffect, useState } from 'react';
import { BiBitcoin, BiDollar, BiEuro } from 'react-icons/bi';
import { getSiaCoinPrice } from '../api';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { SmallLogo } from './Logo';

function CoinStat({ value, icon, loaded, decimalPlaces })
{
    return (
            <Flex alignItems="center">
                {icon}
                <Skeleton isLoaded={loaded}>
                <Text fontSize="sm" minWidth="30" fontWeight="600">
                    {round(value, decimalPlaces)}
                </Text>
                </Skeleton>
            </Flex>
    )
}

const icons =
{
    btc : <BiBitcoin /> ,
    eur : <BiEuro />,
    usd : <BiDollar />
}

export function CoinDisplay()
{
    const [coins, setCoins] = useState({ btc : 0, eur : 0, usd : 0 });
    const [loaded, setLoaded] = useState(false);

    useEffect(() =>
    {
        const getPrice = () =>
        {
            getSiaCoinPrice().then(res =>
            {
                if (res)
                {
                    setLoaded(true);
                    setCoins(res);
                }
            })
        }
        getPrice();
        const interval = setInterval(() => getPrice(), 60000);
        return () => {
            clearInterval(interval);
        }
    }, []);

    return (
        <Stack direction="row" spacing={4} align="center" justifyContent="center">
            {Object.keys(coins).map(c => <CoinStat key={c} loaded={loaded} icon={icons[c]} value={coins[c]} decimalPlaces={c === 'btc' ? 8 : 4}   />)}
        </Stack>
    )
}

export default function Navbar({ logoVisible })
{
    const isSmall = useBreakpointValue({ base: true, md: false });

    return (
        <Box
            bg={useColorModeValue('white', 'gray.800')}
            color={useColorModeValue('blue.800', 'white')}
            minH={'30px'}
            py={{ base: 2 }}
            px={{ base: 4 }}
            borderBottom={1}
            borderStyle={'solid'}
            borderColor={useColorModeValue('gray.200', 'gray.900')}>
            {!isSmall && (
                <Flex align={'center'}>
                    <Fade in={!logoVisible}>
                        <SmallLogo />
                    </Fade>
                    <Spacer />
                    <Stack direction="row" spacing={6} align="center">
                        <CoinDisplay />
                        <ColorModeSwitcher />
                    </Stack>
                </Flex>
            )}
            {isSmall && (
                <Flex align={'center'}>
                    {!logoVisible && <SmallLogo/>}
                    <Spacer />
                    <ColorModeSwitcher />
                </Flex>
            )}
        </Box>
    );
}
