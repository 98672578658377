import axios from "axios";

const baseURL = '';

export async function getSuggestedSettings()
{
    return axios.get(`${baseURL}suggestedsettings.php`)
    .then(({ data })=> {
        return parseFloat(data.minstorageprice);
    })
    .catch((err)=> null)
}

export async function getStorageTotals() {
    return axios.get(`${baseURL}storagetotals.php`)
        .then(({ data }) =>
        {
            return {
                sum : data.network_provider_details_aggregate.aggregate.sum,
                count : data.network_provider_details_aggregate.aggregate.count
            };
        })
        .catch((err) => { })
}

export async function getSiaCoinPrice()
{
    return axios.get('https://api.coingecko.com/api/v3/simple/price?ids=siaprime-coin&vs_currencies=btc,usd,eur')
    .then(({ data })=> data['siaprime-coin'])
    .catch((err)=> null)
}
