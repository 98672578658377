import {
    Box, useColorModeValue
} from '@chakra-ui/react';
import React from 'react';

export default function Card({children, ...props}) {
    return (
        <Box bg={useColorModeValue('white', 'gray.800')} borderRadius="md"  {...props}>
            {children}
        </Box>
    )
}
