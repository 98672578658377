import {
    Box, ChakraProvider, Container,
    extendTheme, Grid, HStack, Image,
    SimpleGrid, Text, useBreakpointValue, useColorMode, useColorModeValue, VStack
} from '@chakra-ui/react';
import { mode } from "@chakra-ui/theme-tools";
import React, { useEffect, useRef, useState } from 'react';
import Calculator from './components/Calculator';
import Card from './components/Card';
import { Logo } from './components/Logo';
import Navbar, { CoinDisplay } from './components/Navbar';
import Stats from './components/Stats';
import useOnScreen from './hooks';

const theme = extendTheme({
    colors: {
        sia: '#2074ee'
    },
    config :
    {
        initialColorMode: "dark"
    },
    useSystemColorMode: false,
    styles: {
        global: (props) => ({
            body: {
                fontFamily: "body",
                color: mode("gray.700", "gray.100")(props),
                bg: mode("gray.100", "gray.800")(props),
                lineHeight: "base",
            },
        }),
    }
})

//<Image src={logo} h="20vmin" pointerEvents="none"  />

function Disclaimer()
{
    const { colorMode } = useColorMode();
    const isDark = colorMode == 'dark';

    return (

        <Box borderWidth="1px" textAlign="left" borderRadius="lg" py={2} px={4} mt={4} mx={ isDark ? 4 : 0 } mb={ isDark ? 0 : 2 }  backgroundColor={useColorModeValue('yellow.300', 'yellow.200')} color={useColorModeValue('gray.800', 'gray.800')}>
            <HStack spacing="2" fontSize="lg"  >
                <Text fontWeight="bold">Disclaimer</Text>
            </HStack>
            <Text fontSize="xs" mt="2" fontWeight="400" textAlign={{ base : 'justify', md : 'left' }}>
                XaMinerStats.com assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness or timeliness.
                This is a community built site and ScPrime Corp. is not part of this site and is not the owner of this site.</Text>
        </Box>
    )
}

function DisclaimerBottom()
{
    const { colorMode } = useColorMode();
    const isDark = colorMode == 'dark';

    return (

        <Box borderWidth="1px" textAlign="left" borderRadius="lg" py={2} px={4} mt={isDark ? 0 : 4} mx={ isDark ? 4 : 0 }  backgroundColor={useColorModeValue('yellow.300', 'yellow.200')} color={useColorModeValue('gray.800', 'gray.800')}>
            <HStack spacing="2" fontSize="lg"  >
                <Text fontWeight="bold">Disclaimer</Text>
            </HStack>
            <Text fontSize="xs" mt="2" fontWeight="400" textAlign={{ base : 'justify', md : 'left' }}>
                XaMinerStats.com assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness or timeliness.
                This is a community built site and ScPrime Corp. is not part of this site and is not the owner of this site.</Text>
        </Box>
    )
}

function Content({ onLogoVisible })
{
    const isSmall = useBreakpointValue({ base: true, md: false });
    const logoRef = useRef()
    const visible = useOnScreen(logoRef, "-100px")
    const hideStats = true;

    useEffect(() => {
        onLogoVisible && onLogoVisible(visible);
        console.log(`visible`, visible);
    }, [visible])

    return (
        <React.Fragment>
            {isSmall && (
                <Card p="4" mt={4} mb="0">
                    <CoinDisplay />
                </Card>
            )}
            <Disclaimer/>
            <SimpleGrid columns={{ base: 1, md: 1 }} spacing={{ base: 5, xl: 8 }} py={{ base : 2, md : 0 }} pb={{ base : 4, md : 0 }} bg={useColorModeValue('white', 'gray.800')} borderRadius="md" >
                <Box ref={logoRef} >
                    <Logo />
                    <Text fontWeight="600" fontSize={{ base : 'sm', md : 'md' }} mt={{ base : 2, md : 0 }} px={4} >
                        for the ScPrime decentralized cloud storage network
                    </Text>
                </Box>
                <Calculator />
            </SimpleGrid>
            {!hideStats && <Stats />}
        </React.Fragment>
    );
}

function Content1()
{
    const isSmall = useBreakpointValue({ base: true, md: false });

    return (
        <React.Fragment>
            {isSmall && (
                <Card p="4" mb="4">
                    <CoinDisplay />
                </Card>
            )}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, xl: 8 }} py={{ base : 0, md : 8 }} pb={{ base : 4, md : 8 }} bg={useColorModeValue('white', 'gray.800')} borderRadius="md" >
                <VStack spacing="2" align="center" mx={{ base : 4, md : 0 }}>
                    <Logo />
                    <Box maxW="sm" borderWidth="1px" textAlign="left" borderRadius="lg" p={"4"}  backgroundColor={useColorModeValue('yellow.300', 'yellow.400')} color={useColorModeValue('gray.800', 'gray.800')}>
                        <HStack spacing="2" fontSize="xl"  >
                            <Text fontWeight="bold">Disclaimer</Text>
                        </HStack>
                        <Text fontSize="sm" mt="2" fontWeight="400" textAlign={{ base : 'justify', md : 'left' }}>
                            XaMinerStats.com assumes no responsibility or liability for any errors or omissions in the content of this site. The information contained in this site is provided on an "as is" basis with no guarantees of completeness, accuracy, usefulness or timeliness.
                            This is a community built site and ScPrime Corp. is not part of this site and is not the owner of this site.</Text>
                    </Box>
                </VStack>
                <Calculator />
            </SimpleGrid>
            <Stats />
        </React.Fragment>
    );
}


function App()
{
    const [logoVisible, setLogoVisible] = useState(true);


    return (
        <ChakraProvider theme={theme} >
            <div style={{ position : 'fixed', top :0, width : '100%', zIndex : 10 }}>
                <Navbar logoVisible={logoVisible} />
            </div>
            <Container maxWidth="container.lg" textAlign="center" my={{ base: 4, md : 4  }} pt={8}>
                <Content onLogoVisible={v => setLogoVisible(v)} />
            </Container>
        </ChakraProvider>
    );
}


export default App;
