import { Flex, Text, Stack, chakra, Image, Box } from '@chakra-ui/react';
import React from 'react';
import imgLogo from '../xaminer_website_header1.png';

/*export const Logo = function(props)
{
    return (
        <Flex alignItems="center" justifyContent="center" color="sia" lineHeight="1.2" mt={-4}>
            <Text fontWeight="bold" fontStyle="italic" fontSize={{ base: '7xl', md: "9xl" }}>X</Text>
            <Text fontWeight="bold" fontStyle="italic" fontSize={{ base: '3xl', md: "6xl" }} ml={{ base: -1, md: -3 }}>a-Miner Stats</Text>
        </Flex>
    );
};

export const SmallLogo = function(props)
{
    return (
        <Flex alignItems="center" justifyContent="center" color="sia" lineHeight="1.2" >
            <Text fontWeight="bold" fontStyle="italic" fontSize={{ base: '3xl' }}>X</Text>
            <Text fontWeight="bold" fontStyle="italic" fontSize={{ base: '2xl' }} ml={{ base: -.5 }}>a-Miner Stats</Text>
        </Flex>
    );
};*/

export function SmallLogo()
{
    return <Box display="flex" alignItems="center">
        <Text
            ml={1}
            fontSize={'xl'}
            fontStyle="italic"
            fontWeight={'600'}>
                Xa-Miner Stats
        </Text>
    </Box>
}

export function Logo()
{
    return <Box display="flex" alignItems="center" justifyContent="center" mt={{ base : 0, md : 2}}>

        <Text
            ml={1}
            fontSize={'4xl'}
            fontStyle="italic"
            fontWeight={'600'}>
                Xa-Miner Stats
        </Text>
    </Box>
}

/*
<Image src={imgLogo} pointerEvents="none" />
                    <Heading fontSize={{ base: '6xl', md: "8xl" }} color="sia" lineHeight="1.1" >
                        Xa Miner Stats
                    </Heading>
*/